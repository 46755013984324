@font-face {
    font-family: Griff;
    font-weight: 400;
    src: url("../public/webFonts/GriffRegular/Griff-Regular.ttf");
}

@font-face {
    font-family: Griff;
    font-weight: 600;
    src: url("../public/webFonts/GriffSemibold/Griff-Semibold.ttf");
}

@font-face {
    font-family: "Proxima Nova";
    font-weight: 400;
    src: url("../public/webFonts/ProximaNovaRegular/font.woff");
}
@font-face {
    font-family: "Proxima Nova";
    font-weight: 600;
    src: url("../public/webFonts/ProximaNovaSemibold/font.woff");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    font-family: Inter;
}

#recap-markdown ol,
#recap-markdown ul {
    padding-inline-start: 24px;
}
